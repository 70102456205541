<template>
  <tr>
    <td>
      <div class="form-check">
        <input v-model="item.for_total_cost" class="form-check-input" type="checkbox"
               tabindex="3"/>
      </div>
    </td>
    <td style="min-width: 250px">
      <vField
          v-model="item.product_costingable_id"
          name="product_costingable_id"
          type="text"
          class="form-control d-none"
      />
      <AsyncSelect
          :api-service="fetchProductList"
          placeholder="Select Product"
          v-model="selectedProduct"
          :additional-query="additionalContactOption"
          :format-label="option => option.text"
          :additional-option="additionalOptions"
          label="text"
      />
    </td>
    <td>
      <div class="td-inline">
        <input
            type="text" class="form-control text-center"
            placeholder="Unit"
            :value="item.unit_of_measurment"
            readonly
        >
      </div>
    </td>
    <td>
      <div class="td-inline">
        <input
            v-model="item.quantity"
            type="number"
            class="form-control text-center"
            placeholder="Qty"
            :min="0"
        >
      </div>
    </td>
    <td>
      <div class="td-inline">
        <input
            v-model="item.wastage"
            type="number"
            class="form-control text-center"
            placeholder="Wastage %"
            :min="0"
        >
      </div>
    </td>
    <td>
      <div class="td-inline">
        <input
            :value="getTotalWastage"
            type="number"
            class="form-control text-center"
            placeholder="Wastage Qty"
            readonly
        >
      </div>
    </td>
    <td>
      <div class="td-inline">
        <input
            :value="getTotalQty"
            placeholder="Total Qty"
            readonly
            type="text"
            class="form-control text-center"
        />
      </div>
    </td>
    <td>
      <div class="td-inline">
        <input
            v-model="item.rate"
            placeholder="Rate"
            type="text"
            class="form-control text-center"
        />
      </div>
    </td>
    <td>
      <div class="td-inline">
        <input
            :value="finalAmount"
            placeholder="Amount"
            type="text"
            class="form-control text-center"
            readonly
        />
      </div>
    </td>
    <td>
      <div>
        <button @click="(e) => deleteItem(e, index)" class="btn btn-sm btn-dlt btn-danger">
          <i class="fas fa-trash"></i>
        </button>
      </div>
    </td>
  </tr>
</template>

<script setup>
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {computed, ref, watch} from 'vue';
import handlePurchaseAndSales from "@/services/modules/purchase";
import {useRoute} from "vue-router";

const props = defineProps(['item', 'index', 'onDeleteItem'])
const emit = defineEmits(['onDeleteItem']);
const route = useRoute();
const {fetchProductList} = handlePurchaseAndSales()
const selectedProduct = ref(props.item.product_costingable);

const additionalContactOption = ref({
  average_price: true
});

const additionalOptions = computed(() => {
  if (route.params.productCostingId && props.item.product_costingable_id && props.item.product) {
    return [
      {
        id: props.item.product_costingable_id,
        text: props.item.product.name,
      }
    ]
  }
});

watch(selectedProduct, (newValue) => {
  if (newValue) {
    props.item.product = {
      name: newValue.text,
      ...newValue
    }
    props.item.rate = newValue.average_price;
    props.item.unit_of_measurment = newValue.description.unit.name;
    props.item.product_costingable_id = newValue.id;
  }
});

const getTotalWastage = computed(() => {
  const wastageQty = (props.item.quantity * props.item.wastage) / 100;
  props.item.wastage_quantity = wastageQty.toFixed(4); // still a string
  return props.item.wastage_quantity;
});

const getTotalQty = computed(() => {
  const wastageQty = parseFloat(props.item.wastage_quantity);
  const totalQty = props.item.quantity + wastageQty;
  if (totalQty == 0){
    props.item.wastage = 0;
    return props.item.total_quantity = 0
  }
  props.item.total_quantity = totalQty.toFixed(4);
  return props.item.total_quantity;
});

const finalAmount = computed(() => {
  const totalQty = parseFloat(props.item.total_quantity);
  const amount = totalQty * props.item.rate;
  props.item.amount = amount.toFixed(4);
  return props.item.amount;
});
const deleteItem = (e, i) => {
  e.stopPropagation();
  emit('onDeleteItem', i)
}
</script>

<style>
.create-billing > :not(first-child) > * {
  padding-left: 10px !important;
}

.create-billing > :not(caption) > * > * {
  padding: 0.72rem 0.2rem;
}
</style>
