<template>
  <div class="card border-1">
    <div class="table-container">
      <table class="table create-billing table-width">
        <thead>
          <tr class="bg-black-light">
            <td class="font-medium-1 p-25" colspan="100%">{{ tableTitle }}</td>
          </tr>
          <tr class="text-center">
            <th>Item Description</th>
            <th>UoM</th>
            <th>Total Qty</th>
            <th>Total Mnf Cost</th>
            <th>Cost %</th>
            <th>Total Cost</th>
            <th>Per Unit Cost</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody ref="tableRef">
            <ProductionComponentItem
                :total-cost="totalCost"
                v-for="(item, i) in tableItems"
                :key="i"
                :item="item"
                :index="i"
                @onDeleteItem="onDeleteItem"
                @costPercentageCheck="costPercentageCheck"
            />
            <tr>
              <td colspan="2">
                <button @click="addNewItem" class="btn btn-outline-primary btn-sm">
                  <i class="fas fa-plus"></i>
                  Add new
                </button>
              </td>
              <th colspan="3" class="text-center">Total</th>
              <th class="text-center">{{ getTotal }}</th>
              <th class="text-center"></th>
            </tr>
          </tbody>
      </table>
      <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import {computed, ref} from 'vue';
import ProductionComponentItem from "./ProductionComponentItem.vue";

const props = defineProps({
  tableTitle: {
    type: String
  },
  tableItems: {
    type: Array
  },
  type: {
    type: String,
    default: 'production'
  },
  totalCost: {
    type: [Number, String],
    default: 0
  }
})
const getTotal = computed(() => {
  return props.tableItems.reduce((total, amount) => total + parseFloat(amount.amount), 0).toFixed(4);
})

const addNewItem = () => {
  props.tableItems.push({
    id: null,
    product: null,
    product_costingable_id: null,
    product_costingable_type: 'App\\Models\\Product',
    quantity: 0,
    wastage: 0,
    total_quantity: 0,
    rate: 0,
    amount: 0,
    type: props.type
  })
}
const onDeleteItem = (index) => {
  props.tableItems.splice(index, 1);
}

const costPercentageCheck = (index, costPercentage) => {
  props.tableItems[index].wastage = costPercentage;

  const otherItemsTotal = props.tableItems
      .filter((_, i) => i !== 0)
      .reduce((acc, item) => acc + item.wastage, 0);

  const remainingForFirstItem = 100 - otherItemsTotal;

  if (remainingForFirstItem >= 0) {
    return props.tableItems[0].wastage = remainingForFirstItem;
  }
  return props.tableItems[0].wastage = 0;
};

</script>

<style>
.create-billing > :not(first-child) > * {
  padding-left: 10px !important;
}

.create-billing > :not(caption) > * > * {
  padding: 0.72rem 0.2rem;
}

</style>
